@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');

.pinatas-banner-area {
    height: 90rem;
    width: 100%;

    .pinatas-content-area {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.452);

        .background {
            object-fit: cover;
            width: 100%;
            height: 100%;
            z-index: -2;
        }

        .pinatas-content {
            text-align: center;
            position: absolute;

            h1 {
                font-size: 80px;
                color: white;
                font-family: 'Dancing Script', cursive;
            }
        }
    }
}

.content {
    padding: 5rem;
    justify-content: center;
    text-align: center;

    .pinatas-content {
        .content-title {
            margin-bottom: 2rem;

            h1 {
                font-family: 'Dancing Script', cursive;
                color: #460874;
            }
    
            p {
                margin-bottom: 1.5rem;
                font-family: 'Questrial', sans-serif;
            }
    
            .line {
                border-top-width: 2px;
                display: block;
                position: relative;
                width: 8rem;
                border-top: 2px solid #460874;
                margin: auto;
            }
        }
    }
}
// ========================================== MEDIA QUERIES ========================
@media screen and (max-width: 1930px) {
    .pinatas-banner-area {
        height: 63rem;
    }

    .content {
        .pinatas-content {
            .content-title {
                margin-bottom: 0.3rem;

                h1 {
                    margin-bottom: 0.6rem;
                }

                p {
                    margin-bottom: 0.9rem;
                }
            }
        }
    }
}

@media screen and (max-width: 1650px) {
    .pinatas-banner-area {
        height: 57rem;
    }

    .content {
        .pinatas-content {
            .content-title {
                margin-bottom: 0.3rem;

                h1 {
                    margin-bottom: 0.6rem;
                }

                p {
                    margin-bottom: 0.9rem;
                }
            }
        }
    }
}

@media screen and (max-width: 390px) {
    .pinatas-banner-area {
        height: 54rem;
    }

    .content {
        padding-left: 2rem;
        padding-right: 2rem;

        .pinatas-content {
            .content-title {
                margin-bottom: 0.3rem;

                h1 {
                    margin-bottom: 0.6rem;
                }

                p {
                    margin-bottom: 0.9rem;
                }
            }
        }
    }
}

@media screen and (max-width: 360px) {
    .pinatas-banner-area {
        height: 50rem;
    }

    .content {
        padding-left: 1rem;
        padding-right: 1rem;

        .pinatas-content {
            .content-title {
                margin-bottom: 0.3rem;

                h1 {
                    margin-bottom: 0.6rem;
                }

                p {
                    margin-bottom: 0.9rem;
                }
            }
        }
    }
}

@media screen and (max-height: 660px) {
    .pinatas-banner-area {
        height: 40rem;
    }

    .content {
        padding-left: 1rem;
        padding-right: 1rem;

        .pinatas-content {
            .content-title {
                margin-bottom: 0.3rem;

                h1 {
                    margin-bottom: 0.6rem;
                }

                p {
                    margin-bottom: 0.9rem;
                }
            }
        }
    }
}