.googleMap {
    width: 500px;
    height: 400px;
}

// ========================================== MEDIA QUERIES ========================
@media screen and (max-width: 850px) {
    .googleMap {
        width: 100%;
        height: 400px;
    }
}
