@import "~react-image-gallery/styles/scss/image-gallery.scss";

.slider {
    z-index: 1;
    position: relative;

    .slider-image {
        height: 27rem;
    }
    
    .slider-thumbs {
        padding: 1rem 0rem;
        box-sizing: border-box;

        .slider-image {
            height: 8rem;
            opacity: 0.4;
            cursor: pointer;
        }
    }

    .slider-thumbs .swiper-slide-thumb-active {
        .slider-image {
            opacity: 1;
        }
    }
}

.review {
    background-color: white;
    border-radius: 25px;

    .image-content, .card-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px 14px;
    }

    .image-content {
        row-gap: 5px;
        position: relative;
        padding: 25px 0;
    }

    .overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background-color: #460874;
        border-radius: 25px 25px 0 25px;
    }

    .overlay::before, .overlay::after {
        position: absolute;
        content: "";
        height: 40px;
        width: 40px;
        bottom: -40px;
        background-color: #460874;
    }

    .overlay::after {
        border-radius: 0 25px 0 0;
        background-color: #fff;
    }

    .card-image {
        position: relative;
        height: 150px;
        width: 150px;
        border-radius: 50%;
        background-color: white;
        padding: 3px;

        .card-img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 50%;
        }
    }

    .name {
        font-size: 18px;
        font-weight: 500;
        color: #333;
    }

    .description {
        font-size: 14px;
        color: #707070;
        text-align: center;
    }
}

// ========================================== MEDIA QUERIES ========================
@media screen and (max-width: 850px) {
    .slider-thumbs {
        display: none;
    }
}