@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');
@font-face {
    font-family: logo-mundo-dulzura;
    src: url(../assets/fonts/Monte-Kristo.ttf);
}

.sobreNosotros-banner-area {
    height: 90rem;
    width: 100%;

    .sobreNosotros-content-area {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.452);

        .background {
            object-fit: cover;
            width: 100%;
            height: 100%;
            z-index: -2;
        }

        .sobreNosotros-content {
            text-align: center;
            position: absolute;

            h1 {
                font-size: 80px;
                color: white;
                font-family: 'Dancing Script', cursive;
            }
        }
    }
}

.about-content {
    .about-content-text {
        padding-top: 5rem;
        padding-bottom: 5rem;
        padding-left: 25rem;
        padding-right: 25rem;

        .cake-icon {
            color: #460874;
            margin-bottom: 1rem;
            font-size: 1.5rem;
            text-align: center;
        }

        p {
            text-align: center;
            line-height: 1.5;
            margin-bottom: 1rem;
            font-family: 'Questrial', sans-serif;
            color: rgb(95, 95, 95);
            font-size: 1.2rem;
        }

        .line {
            border-top-width: 2px;
            display: block;
            position: relative;
            width: 8rem;
            border-top: 2px solid #460874;
            margin: auto;
        }

        .about-content-logo {
            text-align: center;
            color: #460874;
            margin-top: 1rem;

            .logo {
                font-family: logo-mundo-dulzura;
                font-size: 5rem;
                font-weight: 400;
            }
        }
    }
}

// ========================================== MEDIA QUERIES ========================
@media screen and (max-width: 1930px) {
    .sobreNosotros-banner-area {
        height: 63rem;
    }
}

@media screen and (max-width: 1650px) {
    .sobreNosotros-banner-area {
        height: 57rem;
    }
}

@media screen and (max-width: 840px) {
    .about-content {
        display: block;

        .about-content-text {
            padding-left: 15rem;
            padding-right: 15rem;
        }
    }
}

@media screen and (max-width: 660px) {
    .about-content {
        display: block;

        .about-content-text {
            padding-left: 10rem;
            padding-right: 10rem;
        }
    }
}

@media screen and (max-width: 575px) {
    .about-content {
        display: block;

        .about-content-text {
            padding-left: 3rem;
            padding-right: 3rem;

            .about-content-logo {    
                .logo {
                    font-family: logo-mundo-dulzura;
                    font-size: 3rem;
                    font-weight: 400;
                }
            }
        }
    }
}

@media screen and (max-width: 390px) {
    .sobreNosotros-banner-area {
        height: 54rem;
    }
}

@media screen and (max-width: 368px) {
    .about-content {
        display: block;

        .about-content-text {
            padding-left: 1rem;
            padding-right: 1rem;

            .about-content-logo {    
                .logo {
                    font-family: logo-mundo-dulzura;
                    font-size: 3rem;
                    font-weight: 400;
                }
            }
        }
    }
}

@media screen and (max-width: 360px) {
    .sobreNosotros-banner-area {
        height: 50rem;
    }
}

@media screen and (max-height: 660px) {
    .sobreNosotros-banner-area {
        height: 40rem;
    }
}