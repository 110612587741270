* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  scroll-behavior: smooth;
}

html::-webkit-scrollbar {
  width: 0.6rem;
}

html::-webkit-scrollbar-track {
  background: #e7e7e7;
}

html::-webkit-scrollbar-thumb {
  background: #460874;
}