.styles-module_chatHeader__23UA6 {
    align-items: center;
}

.styles-module_messageBody__1hzTp {
    font-family: Arial, Helvetica, sans-serif;    
}

.styles-module_accountName__2jFCW {
    font-family: Arial, Helvetica, sans-serif;
    display: none;
}

.styles-module_statusTitle__1svrc {
    font-family: Arial, Helvetica, sans-serif;
    padding-top: 5px;
}

.styles-module_notificationIndicator__3JOtX {
    height: 1.3rem;
    width: 1.3rem;
}

.styles-module_whatsappChatBox__P75uz.styles-module_open__2W1cE {
    z-index: 13;
}

.styles-module_whatsappButton__IWx9V {
    z-index: 13;
}