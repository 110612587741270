@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.footer {
    width: 100%;
    padding: 4rem 6rem;
    align-items: center;
    background-color: #460874;
    color: white;
}

.information {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    font-family: Poppins,sans-serif;
    font-weight: 300;
    font-size: 19px;
    justify-items: center;

    .navegacion {
        grid-column: 2 / 3;
        display: grid;
        align-content: center;
        margin-bottom: 5rem;
        
        h5 {
            margin-bottom: 1rem;
        }

        .link {
            color: white;
            font-size: 13px;
            padding-right: 0.7rem;
            margin-bottom: 1rem;

            &:hover {
                padding-left: 0.7rem;
                padding-right: 0rem;
                transition: 0.5s;
            }
        }
    }

    .informacion-legal {
        grid-column: 4 / 4;
        text-align: center;

        h5 {
            margin-bottom: 1rem;
        }

        a {
            img {
                height: 85px;
            }
        }
    }

    .contacto {
        grid-column: 3 / 4;
        display: grid;
        align-content: center;
        margin-bottom: 6.9rem;

        h5 {
            margin-bottom: 1rem;
        }

        a {
            display: flex;
            align-items: center;
            color: white;
            font-size: 13px;
            margin-bottom: 1rem;

            .icon {
                margin-right: 1rem;
                font-size: 16px;
            }

            p {
                padding-right: 0.7rem;

                &:hover {
                    padding-left: 0.7rem;
                    padding-right: 0;
                    transition: 0.5s;
                }
            }
        }
    }
}

.copyright {
    align-items: center;
    text-align: center;
    font-family: Poppins,sans-serif;
    font-weight: 400;
    font-size: 13px;
    grid-column: 1 / 2;

    .redes-sociales {
        margin-bottom: 1.5rem;
        display: grid;
        grid-auto-flow: column;
        justify-items: center;

        a {
            color: white;
            font-size: 17px;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            padding-bottom: 0.2rem;
            padding-top: 0.6rem;
            border-radius: 2.5rem;

            &:hover {
                color: #460874;
                background-color: white;
                transition: 0.5s;
            }
        }
    }

    .programador {
        margin-bottom: 0;
        color: rgb(255, 255, 255);
        text-decoration: none;

        a {
            color: rgb(255, 255, 255);
        }

        a:visited {
            color: white;
        }
          
        a:active {
            color: white;
        }
    }

    p {
        margin-bottom: 0.5rem;
    }

    img {
        height: 100px;
        margin-bottom: 1.5rem;
    }
}

// ========================================== MEDIA QUERIES ========================
@media screen and (max-width: 938px) {
    .footer {
        padding: 4rem 7rem;
    }
}

@media screen and (max-width: 915px) {
    .information {
        align-items: center;
        display: flex;
        flex-direction: column-reverse;

        .navegacion {
            justify-items: center;
        }

        .informacion-legal {
            display: none;
        }

        .contacto {
            margin-bottom: 5rem;
            justify-items: center;
        }
        
        .copyright {
            display: block;

            .redes-sociales {
                margin-top: 1.3rem;
            }
        } 
    }
}

@media screen and (max-width: 509px) {
    .footer {
        padding: 4rem 2rem;
    }

    .information {
        .contacto {
            margin-bottom: 3.5rem;
        }

        .navegacion {
            margin-bottom: 3.5rem;
        }
    }
}