.nav-icon-5 {
    width: 35px;
    height: 30px;
    position: relative;
    cursor: pointer;
    display: none;
    margin-bottom: 1rem;

    span {
        background-color:#fff;
        position: absolute;
        border-radius: 2px;
        transition: .3s cubic-bezier(.8, .5, .2, 1.4);
        width:100%;
        height: 4px;
        transition-duration: 500ms
    }

    span:nth-child(1) {
        top:0px;
        left: 0px;
    }

    span:nth-child(2) {
        top:13px;
        left: 0px;
        opacity:1;
    }

    span:nth-child(3) {
        bottom:0px;
        left: 0px;
    }
}

.nav-icon-5.open span:nth-child(1) {
    transform: rotate(45deg);
    top: 13px;
}

.nav-icon-5.open span:nth-child(2) {
    opacity:0;
}

.nav-icon-5.open span:nth-child(3) {
    transform: rotate(-45deg);
    top: 13px;
}

// ========================================== MEDIA QUERIES ========================
@media screen and (max-width: 1046px) {
    .nav-icon-5 {
        display: inline-block;
    }
}

@media screen and (max-width: 460px) {
    .nav-icon-5 {
        width: 30px;
    }
}