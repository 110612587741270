@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');

.header {
    width: 100%;
    display: flex;
    padding: 15px 30px;
    align-items: center;
    background-color: transparent;
    justify-content: space-between;
    color: white;
    position: fixed;
    z-index: 10;
    transition: background-color 0.4s ease-in-out;

    .icons {
        font-size: 1.2rem;
    
        a {
            text-decoration: none;
            color: white;
            padding-left: 0.4rem;
            padding-right: 0.4rem;
            padding-bottom: 0.1rem;
            padding-top: 0.5rem;
            margin-left: 0.2rem;
            border-radius: 3rem;
            background-color: transparent;

            &:hover {
                color: #460874;
                background-color: white;
                transition: background 0.5s, color 0.4s;
            }
        }
    }

    .link {
        text-decoration: none;
        color: white;
    }
    
    .logo {
        width: 15rem;
        margin-left: 2rem;
        margin-right: 2rem;
    }
    
    .social {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }

    .responsive-menu {
        display: none;
        position: fixed;
        top: -700px;
        left: -2000px;
        height: 100%;
        width: 100%;
        background-color: #460874;
        z-index: -1;
        transition: all .6s ease;

        .center {
            height: 100%;
            justify-content: center;
            align-items: center;

            .center-responsive-menu {
                text-align: center;
                margin-top: 50%;
                margin-bottom: 50%;
                
                &.active {
                    border-radius: 0 0 80% 0;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
    
                .link {
                    display: block;
                    margin-bottom: 1rem;
                    font-size: 2rem;
                    font-family: 'Source Sans Pro', sans-serif;
                }
            }
        }
    }

    .responsive-menu.active {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #460874;
        z-index: -1;
        transition: all .6s ease;
        border-radius: 0 0 70% 0;

        .center {
            height: 100%;
            justify-content: center;
            align-items: center;

            .center-responsive-menu {
                text-align: center;
                margin-top: 50%;
                margin-bottom: 50%;
                
                &.active {
                    border-radius: 0 0 80% 0;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
    
                .link {
                    display: block;
                    margin-bottom: 1.5rem;
                    font-size: 2.2rem;
                }
            }
        }
    }

    .menu {
        margin-left: -5.5rem;
        display: flex;
        align-items: center;

        .responsive-space {
            display: none;
        }
    
        ul {
            display: flex;
            position: relative;
            padding: 0;
    
            li {
                position: relative;
                margin: 0px 14px;
                font-family: 'Source Sans Pro', sans-serif;
                font-weight: 500;
                position: relative;
                font-size: 1.1rem;

                &::before {
                    content: "";
                    height: 2.5px;
                    width: 0%;
                    background: white;
                    position: absolute;
                    left: 0;
                    bottom: -5px;
                    transition: 0.4s ease-out;
                }

                &:hover::before {
                    width: 100%;
                }
            }
        }
    }

    .proximamente {
        opacity: 0;
    }
}

.header.active {
    background-color: #460874;
}

// ========================================== MEDIA QUERIES ========================
@media screen and (max-width: 1046px) {
    .header {
        display: block;
        justify-content: center;

        .icons {
            display: none;
        }

        .proximamente {
            display: none;
        }

        .responsive-menu {
            display: block;
        }

        .menu {
            margin-left: 0;
            display: flex;
            justify-content: space-between;
            text-align: center;

            .responsive-space {
                display: block;
                opacity: 0;
            }

            ul {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 565px) {
    .header {
        .logo {
            width: 13rem;
        }
    }
}

@media screen and (max-width: 460px) {
    .header {
        padding: 15px 20px;

        .logo {
            width: 11rem;
        }
    }
}

@media screen and (max-width: 360px) {
    .header {
        .logo {
            width: 10rem;
            margin: 0;
        }
    }
}