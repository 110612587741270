@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');
@font-face {
    font-family: mundo-dulzura;
    src: url(../assets/fonts/Monte-Kristo.ttf);
}

.home {
    .home-banner-area {
        height: 90rem;
        width: 100%;
    
        .home-content-area {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.452);

            .background {
                object-fit: cover;
                width: 100%;
                height: 100%;
                z-index: -2;
            }
    
            .home-content {
                text-align: center;
                position: absolute;
    
                h1 {
                    font-size: 80px;
                    color: white;
                    font-family: 'Dancing Script', cursive;
                }
            }
        }
    }

    .content {
        padding: 5rem;
        justify-content: center;
        text-align: center;
    
        .home-content {
            .content-title {
                margin-bottom: 2rem;
    
                h1 {
                    font-family: 'Dancing Script', cursive;
                    color: #460874;
                }
        
                p {
                    margin-bottom: 1.5rem;
                    font-family: 'Questrial', sans-serif;
                }
        
                .line {
                    border-top-width: 2px;
                    display: block;
                    position: relative;
                    width: 8rem;
                    border-top: 2px solid #460874;
                    margin: auto;
                }
            }
        }

        .home-content-end {
            display: flex;
            align-items: center;

            .content-title.responsive {
                margin-bottom: 2rem;
                display: none;
    
                h1 {
                    font-family: 'Dancing Script', cursive;
                    color: #460874;
                }
        
                p {
                    margin-bottom: 1.5rem;
                    font-family: 'Questrial', sans-serif;
                }
        
                .line {
                    border-top-width: 2px;
                    display: block;
                    position: relative;
                    width: 8rem;
                    border-top: 2px solid #460874;
                    margin: auto;
                }
            }

            .content-title {
                margin: auto;
    
                h1 {
                    font-family: 'Dancing Script', cursive;
                    color: #460874;
                }
        
                p {
                    margin-bottom: 1.5rem;
                    font-family: 'Questrial', sans-serif;

                    a {
                        color: #460874;
                        position: relative;

                        &::before {
                            content: "";
                            height: 2.5px;
                            width: 0%;
                            background: #460874;
                            position: absolute;
                            left: 0;
                            bottom: -5px;
                            transition: 0.4s ease-out;
                        }
        
                        &:hover::before {
                            width: 100%;
                        }
                    }
                }
        
                .line {
                    border-top-width: 2px;
                    display: block;
                    position: relative;
                    width: 8rem;
                    border-top: 2px solid #460874;
                    margin: auto;
                }
            }
        }
    
        .home-content-propuestas {
            //display: grid;
            display: none;
            grid-template-columns: repeat(3, 1fr);
            gap: 2.5rem;
            margin-top: 2rem;
    
            .propuesta-card {
                border-radius: 1rem;

                &:hover {
                    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                    transition: 0.5s;
                }
    
                .propuesta-image {
                    border-radius: 0.6rem;
                    height: 10rem;
    
                    img {
                        height: 100%;
                    }
                }
    
                .propuesta-body {
                    padding: 1.2rem;
                }
            }
        }
    }

    #novedades {
        display: none;
    }
}

// ========================================== MEDIA QUERIES ========================
@media screen and (max-width: 1930px) {
    .home {
        .home-banner-area {
            height: 63rem;
        }
    }
}

@media screen and (max-width: 1650px) {
    .home {
        .home-banner-area {
            height: 57rem;
        }
    }
}

@media screen and (max-width: 850px) {
    .home {
        .home-banner-area {
            .home-content-area {
                .home-content {
                    .content-title {
                        margin-bottom: 0.5rem;

                        h1 {
                            font-size: 70px;
                        }
                    }
                }
            }
        }

        .content {
            .home-content-end {
                display: block;
                justify-content: center;
                align-items: center;
                text-align: center;
    
                .content-title.responsive {
                    display: block;

                    h1 {
                        margin-bottom: 0.6rem;
                    }

                    p {
                        margin-bottom: 0.9rem;
                    }
                }
    
                .content-title {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 770px) {
    .home {
        .home-banner-area {
            .home-content-area {
                .home-content {
                    h1 {
                        font-size: 60px;
                    }
                }
            }
        }

        .content {
            .home-content {
                .content-title {
                    margin-bottom: 0.3rem;

                    h1 {
                        margin-bottom: 0.6rem;
                    }

                    p {
                        margin-bottom: 0.9rem;
                    }
                }
            }
            .home-content-propuestas {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}

@media screen and (max-width: 520px) {
    .home {
        .home-banner-area {
            .home-content-area {
                .home-content {
                    h1 {
                        font-size: 60px;
                    }
                }
            }
        }

        .content {
            padding-left: 2rem;
            padding-right: 2rem;

            .home-content-propuestas {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }
}


@media screen and (max-width: 390px) {
    .home {
        .home-banner-area {
            height: 54rem;
        }
    }
}

@media screen and (max-width: 360px) {
    .home {
        .home-banner-area {
            height: 50rem;
        }
    }
}

@media screen and (max-width: 350px) {
    .home {
        .content {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
}

@media screen and (max-height: 660px) {
    .home {
        .home-banner-area {
            height: 40rem;
        }
    }
}